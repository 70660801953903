body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
html,
body,
#app,
#app,
#root > div {
    height: 100%;
    background-image: url('../src/assets/bg.png');
    background-repeat: repeat;
}
#root {
    height: 100%;
    background-image: url('../src/assets/bg.png');
    background-repeat: repeat;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
@media (min-width: 900px) {
    .menu-header {
        display: none important;
    }
}

@media (max-width: 900px) {
    .menu-button {
        display: none !important;
    }
}
